import React from 'react';
import { useInView } from 'react-intersection-observer';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="bg-white pt-32 md:pt-64 pb-24 px-8 sm:px-12 md:px-32 lg:pl-56 xl:pl-64">
      <div className="max-w-xl highlight-text text-navy">
        <p ref={ref} className={`animated ${inView ? 'fadeIn' : 'fadeOut'}`}>
          In early 2017,{' '}
          <span
            className={`transition-bg transition-1s transition-delay-500ms ${
              inView ? 'bg-turquoise' : 'bg-white'
            }`}
          >
            Afterpay partnered with Paloma to design and develop iOS and
            Android apps.
          </span>{' '}
          The result of this early partnership was a beautiful mobile product
          that would change the face of Australian retail.
        </p>
      </div>
    </div>
  );
};
