import React from 'react';

import Hero from '../../components/03-dovetail/hero';
import Intro from '../../components/03-dovetail/intro';
import AppStore from '../../components/03-dovetail/app-store';
import AppDownloads from '../../components/03-dovetail/app-downloads';
import NewLevels from '../../components/03-dovetail/new-levels';

export default ({ id, className, forwardRef }) => (
  <div id={id} className={className} ref={forwardRef}>
    <div className="nav-text">
      <div>03. Enter Paloma</div>
    </div>
    <Hero />
    <Intro />
    <AppStore />
    <AppDownloads />
    <NewLevels />
  </div>
);
