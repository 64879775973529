import React from 'react';

export default ({ className = "", width = 1361, height = 802, offsetY = 0 }) => (
  <svg
  className={className}
  width={width}
  height={height}
  viewBox="0 0 1361 802"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <mask
    id="dovetail_vapor_mask0"
    masktype="alpha"
    maskUnits="userSpaceOnUse"
    x="0"
    y="0"
    width="1441"
    height="800"
  >
    <rect x="0.76416" width="1440" height="800" fill="#B1F8FC" />
    <rect x="0.76416" width="1440" height="800" fill="url(#dt_vapor_paint0_linear)" />
  </mask>
  <g mask="url(#dovetail_vapor_mask0)">
    <mask
      id="dovetail_vapor_mask1"
      masktype="alpha"
      maskUnits="userSpaceOnUse"
      x="260"
      y="-159"
      width="1089"
      height="1141"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M713.245 411.444L832.041 579.398V243.489L713.245 411.444ZM373.854 891.354H824.211C868.87 891.354 910.587 869.968 935.947 834.209L1235.62 411.385L936.006 -11.3814C910.587 -47.1395 868.87 -68.4689 824.211 -68.4689H373.854L656.695 331.543L761.9 182.75C784.2 151.212 824.505 137.582 862.162 148.874C899.937 160.223 925.297 193.643 925.297 231.968V590.975C925.297 629.299 899.937 662.662 862.162 674.011C824.505 685.36 784.2 671.673 761.9 640.192L656.695 491.399L373.854 891.354ZM824.212 981.748H331.904C305.426 981.748 281.36 967.662 269.004 944.964C256.648 922.266 258.295 895.005 273.241 873.79L600.152 411.444L273.241 -50.9026C258.295 -72.118 256.648 -99.3785 269.004 -122.077C281.36 -144.775 305.426 -158.861 331.904 -158.861H824.212C899.586 -158.861 970.075 -122.818 1012.85 -62.5939L1348.77 411.386L1012.85 885.424C970.075 945.705 899.586 981.748 824.212 981.748Z"
        fill="#B1F8FC"
      />
    </mask>
    <g mask="url(#dovetail_vapor_mask1)">
      <rect
        x="118.288"
        y={-18 + offsetY}
        width="1254.48"
        height="112.063"
        fill="#B1F8FC"
      />
      <rect
        x="118.288"
        y={142.366 + offsetY}
        width="1254.48"
        height="110.131"
        fill="#B1F8FC"
      />
      <rect
        x="118.288"
        y={300.8 + offsetY}
        width="1254.48"
        height="81.1491"
        fill="#B1F8FC"
      />
      <rect
        x="118.288"
        y={430.253 + offsetY}
        width="1254.48"
        height="59.8958"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.9"
        x="118.288"
        y={528.79 + offsetY}
        width="1254.48"
        height="44.4388"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.8"
        x="118.288"
        y={598.347 + offsetY}
        width="1254.48"
        height="34.7782"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.6"
        x="118.288"
        y={654.378 + offsetY}
        width="1254.48"
        height="21.2533"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.6"
        x="118.288"
        y={689.157 + offsetY}
        width="1254.48"
        height="13.5249"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.5"
        x="118.288"
        y={714.274 + offsetY}
        width="1254.48"
        height="11.5927"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.3"
        x="118.288"
        y={739.392 + offsetY}
        width="1254.48"
        height="7.72849"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.3"
        x="118.288"
        y={760.645 + offsetY}
        width="1254.48"
        height="5.79637"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.2"
        x="118.288"
        y={781.898 + offsetY}
        width="1254.48"
        height="3.86424"
        fill="#B1F8FC"
      />
      <rect
        opacity="0.1"
        x="118.288"
        y={799.288 + offsetY}
        width="1254.48"
        height="1.93212"
        fill="#B1F8FC"
      />
    </g>
  </g>
  <defs>
    <linearGradient
      id="dt_vapor_paint0_linear"
      x1="1440.76"
      y1="-7.36803e-05"
      x2="67.2641"
      y2="800"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="white" />
      <stop offset="1" stopColor="#B1F8FC" />
    </linearGradient>
  </defs>
</svg>

);
