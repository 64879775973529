import React from 'react';
import { useInView } from 'react-intersection-observer';

import TransitionSpan from '../transition-span';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="py-24 md:py-32 lg:py-48 px-6 sm:px-12 md:px-20 lg:px-40 lg:px-48">
      <div className="max-w-2xl mx-auto">
        <p
          ref={ref}
          className={`highlight-text-expanded text-navy animated ${
            inView ? 'fadeIn' : 'fadeOut'
          }`}
        >
          Whilst Paloma were building the mobile apps, the{' '}
          <TransitionSpan
            inView={inView}
            prop="bg"
            a="bg-transparent"
            b="bg-turquoise"
            delay={250}
          >
            exponential growth of users was starting to put a serious strain on
            Afterpay’s web infrastructure
          </TransitionSpan>
          . During marketing campaigns,{' '}
          <TransitionSpan
            inView={inView}
            prop="bg"
            a="bg-transparent"
            b="bg-turquoise"
            delay={500}
          >
            Afterpay was becoming one of the most visited sites in Australia
          </TransitionSpan>
          . The website began crashing frequently, customers became frustrated —
          something needed to be done.
        </p>
      </div>
    </div>
  );
};
