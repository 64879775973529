import React from 'react';

import StickyScroller from '../../../components/sticky-scroller';
import HeartPhone from './heart-phone';
import LazyLoadWrap from '../../lazy-load-wrap';

const Desktop = () => {
  return (
    <StickyScroller>
      {({ inViewRatio, scrollProgress, topProgress, height, rect }) => {
        const halfHeight = height / 2;
        const inViewHeart = halfHeight - rect.top;
        const inViewPhone = rect.height - (rect.top + rect.height);

        return (
          <div id="dt-heart" className="relative h-full overflow-hidden">
            <div
              className={`bg ${
                inViewHeart >= halfHeight ? 'bg-turquoise' : 'bg-white'
              }`}
            />
            <div className="relative z-10 flex w-full">
              <div className="relative w-3/5 pt-24 lg:ml-32 xl:ml-40 px-8 xl:px-20">
                <div className="relative max-w-md text-navy">
                  <p
                    className={`body-text absolute animated ${
                      inViewRatio >= 0.1 && inViewRatio < 0.45
                        ? 'fadeIn'
                        : 'fadeOut'
                    }`}
                  >
                    At the outset of the project, Afterpay’s CEO Nick Molnar
                    laid down the challenge - 'we need to launch a mobile app in
                    twelve weeks'. This was an almost impossible timeframe; the
                    first version of the app included the launch of in-store
                    shopping, a new channel for Afterpay. 'How does 16 weeks
                    sound?' countered Paloma’s Nick Frandsen. Both parties left the
                    meeting with a handshake, and an agreement:
                    <span
                      className={`${
                        inViewRatio >= 0.1 && inViewRatio < 0.45
                          ? 'fadeIn'
                          : 'fadeOut'
                      } animated delay-500ms highlight-text text-navy block mt-20`}
                    >
                      <span className="bg-turquoise">
                        Paloma would deliver Afterpay’s first mobile app in
                        twelve weeks
                      </span>
                    </span>
                  </p>
                  <p
                    className={`body-text absolute animated ${
                      inViewRatio >= 0.5 && inViewRatio < 0.8
                        ? 'fadeIn'
                        : 'fadeOut'
                    }`}
                  >
                    Initially the launch was kept a secret, but the Afterpay
                    fan-club quickly got whiff of it and the app took off.
                    Within 24 hours of launch, Nick Molnar decided to go all-in
                    and kick off an Australian-wide marketing effort. The
                    Afterpay iOS app soared to #1 in the App Store within 48
                    hours. This gave some of the Paloma engineers a mild panic
                    attack, but the launch came off without a hitch. Soon
                    Afterpay soared above Facebook, YouTube and Instagram in app
                    ratings with
                    <span
                      className={`${
                        inViewRatio >= 0.5 && inViewRatio < 0.8
                          ? 'fadeIn'
                          : 'fadeOut'
                      } animated delay-500ms highlight-text block mt-20`}
                    >
                      <span className="bg-neon-lavender">
                        an average of 4.9/5 stars
                      </span>
                      <br />
                      <span className="bg-neon-lavender">
                        on Apple’s iTunes Store
                      </span>
                    </span>
                  </p>
                </div>
              </div>
              <div className="w-2/5 pt-40 mr-20">
                <div className="relative mb-32">
                  <HeartPhone height={height} inViewPhone={inViewPhone} inViewHeart={inViewHeart} />
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </StickyScroller>
  );
};

export default LazyLoadWrap(Desktop, '200vh');
