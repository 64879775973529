import React from 'react';

export default ({
  children,
  inView = false,
  prop = 'color',
  duration = 500,
  delay = 0,
  a = 'text-white',
  b = 'text-special-red',
}) => (
  <span
    className={`transition-${prop} transition-${duration}ms transition-delay-${delay}ms ${
      inView ? b : a
    }`}
  >
    {children}
  </span>
);
