import React from 'react';
import { useInView } from 'react-intersection-observer';

import ScrollTracker from '../scroll-tracker';
import PolyclusterLg from '../polycluster-lg';
import TransitionSpan from '../transition-span';
import isMobile from '../../utils/is-mobile';
import Counter from '../counter';

export default () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = mobile ? 0 : top < height && top > -height ? top : 0;

        return (
          <div className="relative w-full flex flex-col-reverse lg:flex-row">
            <div className="lg:w-3/4 bg-navy pb-32 lg:py-64 px-6 sm:px-12 md:px-20 lg:px-40 xl:px-48">
              <div className="lg:max-w-sm lg:mx-auto">
                <p
                  className={`body-text text-white animated delay-500ms ${
                    inView ? 'fadeIn' : 'fadeOut'
                  }`}
                >
                  As more retailers started accepting Afterpay in-store, the
                  engagement stats for the app went ballistic. Within twelve
                  months of launch mobile traffic exceeded desktop traffic, and
                  it has remained that way to the present day. To date the
                  Afterpay app has been downloaded by over{' '}
                  <TransitionSpan inView={inView} delay={250}>
                    6.3 million customers
                  </TransitionSpan>
                  , with over{' '}
                  <TransitionSpan inView={inView} delay={500}>
                    4.6 million monthly active users
                  </TransitionSpan>{' '}
                  across the globe. In addition to this the app is widely loved
                  by customers, with an average review of{' '}
                  <TransitionSpan inView={inView} delay={750}>
                    4.9/5 stars on Apple’s iTunes Store
                  </TransitionSpan>{' '}
                  – a feat accomplished by no other app in the{' '}
                  <TransitionSpan inView={inView} delay={1000}>
                    Australian Top 50 app chart
                  </TransitionSpan>
                  .
                </p>
              </div>
            </div>
            <div
              ref={ref}
              className="px-6 sm:px-12 md:px-20 py-20 lg:w-1/4"
              style={{
                background: 'linear-gradient(0deg, #143673 0%, #FF5A7D 100%)',
              }}
            >
              <div className="relative flex h-full text-center lg:text-left">
                <div
                  className={`relative big-stat-text text-white mx-auto two-point-five-mil animated ${
                    inView ? 'fadeIn' : 'fadeOut'
                  } self-center`}
                >
                  <Counter
                    inView={inView}
                    a={1000000}
                    b={6300000}
                  />+
                  <div className="w-full date-text text-white mt-4">
                    App Downloads
                  </div>
                </div>
              </div>
            </div>
            {!mobile && <PolyclusterLg
              a="#C99CEF"
              b="#B1F8FC"
              e="#B1F8FC"
              offsetY={offsetY}
              className="absolute w-full pin-x pin-b mx-auto"
            />}
          </div>
        );
      }}
    </ScrollTracker>
  );
};
