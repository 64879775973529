import React from 'react';

import Desktop from './app-store/desktop';
import Mobile from './app-store/mobile';

export default () => {
  return (
    <React.Fragment>
      <div className="hidden lg:block">
        <Desktop />
      </div>
      <div className="lg:hidden">
        <Mobile />
      </div>
    </React.Fragment>
  );
};
