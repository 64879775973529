import React from 'react';

import SpriteWrap from '../../sprite-wrap';
import DTHeart from '../../../images/03-dovetail/app-store-dt-heart.inline.svg';
import AfterpayAppSprite from '../../../images/03-dovetail/app-store-afterpay-app-sprite.inline.svg';

export default ({ inViewHeart = false, inViewPhone = false, height = 1000, halfHeight = 500 }) => {
  return (
    <React.Fragment>
      <div
        id="dt-heart-circle"
        className={`${
          inViewHeart >= halfHeight ? 'bg-white' : 'bg-special-red'
        }`}
        style={{ paddingTop: '100%' }}
      />
      <div
        id="dt-heart-sprite"
        className={`${
          inViewHeart >= halfHeight ? 'transition-out' : ''
        }`}
      >
        <SpriteWrap
          inView={inViewHeart}
          height={halfHeight}
          numFrames={24}
        >
          <DTHeart className="block" />
        </SpriteWrap>
      </div>
      <div
        id="dt-afterpay-app"
        className={`${
          inViewHeart >= halfHeight ? 'transition-in' : ''
        }`}
      >
        <SpriteWrap
          inView={inViewPhone}
          height={height}
          numFrames={14}
        >
          <AfterpayAppSprite className="block" />
        </SpriteWrap>
      </div>
    </React.Fragment>
  );
};
