import React from 'react';

import HeroText from '../../components/hero-text';
import HighlightTextHeading from '../../components/highlight-text-heading';
import DoveTailVapor from './hero/dovetail-vapor';
import ScrollTracker from '../scroll-tracker';
import PolyclusterLg from '../polycluster-lg';
import isMobile from '../../utils/is-mobile';
import LazyLoadWrap from '../lazy-load-wrap';

const hero = () => {
  return (
    <ScrollTracker>
      {({ rect: { top, height } }) => {
        const mobile = isMobile();
        const offsetY = top < height && top > -height ? top : 0;

        return (
          <div className="hero">
            <div className="bg">
              <DoveTailVapor
                className="absolute pin-x mx-auto"
                width="100%"
                height="100%"
                offsetY={offsetY / 6}
              />
            </div>
            <div className="hero-text-container">
              <HeroText date="February 2017" lineA="Enter" lineB="Paloma" />
              <HighlightTextHeading
                bgColor="navy"
                textColor="white"
                lineColor="special-red"
              >
                Taking Afterpay mobile
              </HighlightTextHeading>
            </div>
            {!mobile && <PolyclusterLg
              className="absolute pin-b w-full mx-auto"
              offsetY={offsetY}
            />}
          </div>
        );
      }}
    </ScrollTracker>
  );
};

export default LazyLoadWrap(hero);
