import React from 'react';
import { useInView } from 'react-intersection-observer';

import StickyScroller from '../../../components/sticky-scroller';
import HeartPhone from './heart-phone';
import Polycluster from '../../../images/03-dovetail/app-store-polycluster.inline.svg';

export default () => {
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const [ref2, inView2] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  return (
    <div className="">
      <div className="px-6 sm:px-12 md:px-20">
        <p ref={ref1} className={`text-navy body-text mb-24 animated ${inView1 ? 'fadeIn' : 'fadeOut'}`}>
          At the outset of the project, Afterpay’s CEO Nick Molnar laid down the
          challenge - 'we need to launch a mobile app in twelve weeks'. This was
          an almost impossible timeframe; the first version of the app included
          the launch of in-store shopping, a new channel for Afterpay. 'How does
          16 weeks sound?' countered Paloma’s Nick Frandsen. Both parties left the
          meeting with a handshake, and an agreement:
          <span className={`block highlight-text mt-12 animated delay-500ms ${inView1 ? 'fadeIn' : 'fadeOut'}`}>
            <span className="bg-turquoise">
              Paloma would deliver Afterpay’s first mobile app in twelve weeks
            </span>
          </span>
        </p>
      </div>
      <div className="px-6 sm:px-12 md:px-20">
        <p ref={ref2} className={`text-navy body-text mb-24 animated ${inView2 ? 'fadeIn' : 'fadeOut'}`}>
          Initially the launch was kept a secret, but the Afterpay fan-club
          quickly got whiff of it and the app took off. Within 24 hours of
          launch, Nick Molnar decided to go all-in and kick off an
          Australian-wide marketing effort. The Afterpay iOS app soared to #1 in
          the App Store within 48 hours. This gave some of the Paloma
          engineers a mild panic attack, but the launch came off without a
          hitch. Soon Afterpay soared above Facebook, YouTube and Instagram in
          app ratings with
          <span className={`block highlight-text mt-12 animated delay-500ms ${inView2 ? 'fadeIn' : 'fadeOut'}`}>
            <span className="bg-neon-lavender">an average of 4.9/5 stars</span>
            <br />
            <span className="bg-neon-lavender">on Apple’s iTunes Store</span>
          </span>
        </p>
      </div>
      <StickyScroller>
        {({ height, rect }) => {
          const halfHeight = height / 2;
          const inViewHeart = halfHeight - rect.top;
          const inViewPhone = rect.height - (rect.top + rect.height);

          return (
            <div
              className={`w-full h-full  ${
                inViewHeart >= halfHeight ? 'bg-turquoise' : 'bg-white'
              }`}
              style={{
                transition: 'background 1s 500ms',
              }}
            >
              <Polycluster className="absolute pin-t pin-x block -mt-10 mx-auto" />
              <div className="flex w-full overflow-hidden h-full px-6 sm:px-12 md:px-20">
                <div className="relative w-full self-center">
                  <HeartPhone
                    height={height}
                    halfHeight={halfHeight}
                    inViewPhone={inViewPhone}
                    inViewHeart={inViewHeart}
                  />
                </div>
              </div>
            </div>
          );
        }}
      </StickyScroller>
    </div>
  );
};
